import React from 'react';
import slack from './slack.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        
        <a
          className="App-link"
          href="https://slack.com/oauth/authorize?scope=incoming-webhook&client_id=809893643681.817342298548"
          target="_blank"
          rel="noopener noreferrer"
        >
        <img src={slack} className="App-logo" alt="logo" />
        </a>
      </header>
    </div>
  );
}

export default App;
